<template>
  <div class="content" v-loading="loading">
    <breadcrumb :items="currentLocation" class="bread-crumb" />
    <div class="info">
      <DataDetailInfo :book="item" :isDetail="true" @showAllBooks="showAllBooks" />
    </div>
    <DataDetailUnits :units="units" :subjects="subjects" @viewBooks="viewBooks" />
    <div class="list-container" v-if="false">
      <div class="top">
        <p>全部订购单元</p>
      </div>
      <div class="package-list">
        <package-info
          v-for="pack in packages"
          :key="pack.ruid"
          :item="pack"
          @passId="getBookList"
          @star="starPack"
        />
      </div>
    </div>
    <div class="book-top">
      <span>书目明细</span>
      <template v-if="unit">
        <span>订购单元名称：{{ unit.name }}</span>
        <span v-if="unit.subject">学科：{{ unit.subject }}</span>
        <span v-if="unit.publishYearStart">
          收录时间范围：{{ `${unit.publishYearStart}-${unit.publishYearEnd}` }}
        </span>
        <span v-if="unit.bookCount">资源数量：{{ unit.bookCount }}</span>
        <span v-if="unit.price">价格：{{ `${unit.priceType || ''}${unit.price}` }}</span>
      </template>
    </div>
    <div class="list-container">
      <div class="book-list">
        <data-book-list :unitId="unitId" :key="listKey" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from 'components/Breadcrumb'
import PackageInfo from './PackageInfo'
import DataBookList from './DataBookList'
import DataDetailInfo from './DataDetailInfo'
import DataDetailUnits from './DataDetailUnits'

export default {
  name: 'DataDetail',
  components: {
    Breadcrumb,
    PackageInfo,
    DataBookList,
    DataDetailInfo,
    DataDetailUnits,
  },
  data() {
    return {
      currentLocation: [
        { name: '数据库', path: 'DataList' },
        { name: '数据库详情', path: '' },
      ],
      item: {},
      packages: [],
      listObj: null,
      loading: false,
      listName: '',
      units: [],
      subjects: [],
      unit: null,
      unitId: '',
      listKey: 0,
    }
  },
  computed: {
    bookId() {
      return this.$route.query.id
    },
  },
  mounted() {
    this.getItemInfo()
    this.getUnits()
  },
  methods: {
    showAllBooks() {
      this.unit = null
      this.unitId = ''
      this.listKey++
    },
    viewBooks(unit) {
      this.unit = unit
      this.unitId = unit.ruid
      this.listKey++
    },
    async getUnits() {
      try {
        const query = this.$qs.stringify({
          collectionRuid: this.$route.query.id,
        })
        const res = await this.$http.get(
          `/api/v1/datasearch/admin_ebook_collection_unit_list?${query}`
        )
        const subjects = new Set()
        this.units = res.returnvalue.recordlist.map((item) => {
          item.priceLabel = `${item.priceType || ''}${item.price || ''}`
          const subjectArr = item.subject.split(';')
          subjectArr.forEach((sub) => {
            subjects.add(sub)
          })
          return item
        })
        this.subjects = Array.from(subjects)
      } catch (err) {
        console.log(err)
      }
    },
    async starPack(url) {
      const res = await this.$http.get(url)
      if (res.cxajaxrc != 0) return false

      let msg = ''
      if (url.includes('cancel')) {
        msg = '取消评论成功'
      } else msg = '评论成功'

      this.getItemInfo().then(() => this.$message.success(msg))
    },
    starData(data) {
      if (data.stared) return this.cancelStar(data.ruid)
      else return this.doStar(data.ruid)
    },
    async cancelStar(id) {
      const res = await this.$http.get(
        '/api/v1/my/favor/cancel?dataType=1987055001&outRuid=' + id
      )
      if (res.cxajaxrc != 0 || !res.returnvalue) return false

      this.$message.success('取消收藏成功')
      this.getItemInfo()
    },
    async doStar(id) {
      const res = await this.$http.get(
        '/api/v1/my/favor/do?dataType=1987055001&outRuid=' + id
      )
      if (res.cxajaxrc != 0 || !res.returnvalue) return false

      this.$message.success('收藏成功')
      this.getItemInfo()
    },
    buttonClick(button) {
      console.log(button)
    },
    getBookList(obj) {
      this.listObj = obj
      this.listName = obj.name
    },
    async getItemInfo() {
      this.loading = true
      const id = this.bookId
      const res = await this.$http.get(
        '/api/v1/datasearch/getEbookCollectionDetail?ruid=' + id
      )

      const val = res.returnvalue[0]

      let item = val
      this.item = item

      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 50px;
  word-break: break-all;
  .bread-crumb {
    margin: 10px 0;
  }
  .info {
    padding: 20px;
    border: 1px solid #e6e6e6;
    /deep/ .left {
      width: 240px;
      margin: 0 20px;
    }
  }
  .book-top {
    margin-top: 20px;
    padding: 10px 30px;
    background-color: #eee;
    span {
      display: inline-block;
      margin-right: 20px;
      font-size: 18px;
      line-height: 24px;
    }
  }
  .list-container {
    margin-top: 20px;
    // border: 1px solid #e6e6e6;
    .top {
      background-color: #eee;
      padding: 10px 20px;
      .list-name {
        color: $mainColor;
      }
    }
    .package-list {
      padding: 20px;
      > * {
        padding: 20px 0;
        border-bottom: 1px dashed #e6e6e6;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
  }
}
</style>
