<template>
  <div class="book-detail">
    <div class="left">
      <div class="cover">
        <div class="dbdiv">
          <img class="dbimg" :src="getImage" >
        </div>
      </div>
    </div>
    <div class="info">
      <div class="main-title" v-html="book.name" />
      <div class="sub-title" v-html="book.namecn" />
      <div class="detail">
        <DetailLine title="平台网址" :content="book.homeurl" link block />
        <p>
          <DetailLine title="数据库厂商" :content="book.manufacturerCustomerName" />
          <DetailLine title="出版社" :content="book.publisher" />
          <DetailLine title="收录时间范围" :content="publishTimeRange" />
        </p>
        <p>
          <DetailLine title="学科分类" :content="book.subject" />
          <DetailLine title="读者群" :content="book.reader" />
        </p>
        <p>
          <DetailLine title="内容类型" :content="book.contenttype" />
          <DetailLine title="文献揭示层次" :content="book.hierarchy" />
          <DetailLine title="语种" :content="book.language" />
        </p>
        <p>
          <DetailLine title="资源量" :content="book.bookCount" red />
          <DetailLine title="订购单元" :content="book.unitCount" red />
          <DetailLine title="是否支持单册销售" :content="book.salesingle" />
        </p>
        <DetailLine title="数据库简介" :content="book.description" block />
      </div>
      <div class="buttons">
        <el-button type="primary" @click="showAllBooks">浏览全部书目</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import urlSet from '@/requests/url'

export default {
  name: '',
  props: {
    book: Object,
  },
  data() {
    return {
      noImg: require('images/cnki.png'),
    }
  },
  computed: {
    publishTimeRange() {
      const range = `${this.book.publishYearStart || ''}-${this.book.publishYearEnd || ''}`
      return range === '-' ? '' : range
    },
    getImage() {
      const id = this.book.logopath
      if (!id) return this.noImg
      return id.substring(0, 4) === 'http' ? id : `${urlSet.fileUrl}/api/v1/fileredirect/file?ruid=${id}`
    },
  },
  methods: {
    showAllBooks() {
      this.$emit('showAllBooks')
    },
  }
}
</script>

<style lang="scss" scoped>
  .book-detail {
    display: flex;
    .dbdiv{
      position: relative;
      height: 300px;
      width: 300px;
      border: 1px solid #e1e1e1;
      text-align: center;
      vertical-align: middle;
      // cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      overflow: hidden;
      .dbimg{
        display: inline-block;
        max-width: 298px;
        max-height: 298px;
        width: auto;
        height: auto;
      }
    }
    .left {
      width: 300px;
      min-width: 300px;
      margin: 0 !important;
      .cover {
        .image-links {
          margin-top: 15px;
          text-align: center;
          span {
            font-size: 1rem;
            cursor: pointer;
          }
          .share {
            padding-right: 10px;
            border-right: 1px solid #E6E6E6;
          }
          .fav {
            // padding-left: 10px;
            i {
              margin-right: 5px;
            }
          }
        }
      }
      .count {
        margin-top: 15px;
        text-align: center;
        p {
          .name {
            color: #999;
          }
          .value {
            color: #ee4e0f;
          }
        }
      }
    }
    .info {
      word-break: break-word;
      margin-left: 30px;
      font-family: Microsoft YaHei, Microsoft YaHei-Bold;
      .main-title{
        font-size: 20px;
        font-weight: 700;
        color: #333333;
        max-width:750px;
        word-break: break-word;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .sub-title{
        font-size: 20px;
        color: #333333;
        max-width:750px;
        word-break: break-word;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      > * {
        margin-bottom: 10px;
      }
      .buttons {
        margin-top: 20px;
        button {
          font-size: 16px;
        }
      }
    }
    .is-link {
      h4 {
        cursor: pointer;
        &:hover {
          color: $mainColor;
        }
      }
    }
  }
</style>
