<template>
  <div class="package" v-loading="loading">
    <div class="top">
      <h4>{{ item.index + 1 }}、 {{ item.name }}</h4>
      <div class="buttons">
        <el-button
          v-for="button in item.buttons"
          :key="button.ruid"
          :type="button.type"
          @click="buttonClick(button)"
        >
          {{ button.text }}
        </el-button>
      </div>
    </div>
    <div class="info">
      <div class="row">
        <template v-if="item.unitcount">
          <b>订购单元：</b>
          <span>{{ item.unitcount }}</span>
        </template>
        <template v-if="item.bookcount">
          <b>资源数量：</b>
          <span>{{ item.bookcount }}</span>
        </template>
        <template v-if="item.bookcnypricecount">
          <b>预估总价：</b>
          <span>{{ item.bookcnypricecount }}</span>
        </template>
      </div>
      <div class="row">
        <template v-if="item.publisherYearStart || item.publisherEnd">
          <b>出版时间范围：</b>
          <span>
            {{ item.publisherYearStart || '未知' + '-' + item.publisherEnd || '未知' }}
          </span>
        </template>
        <template v-if="item.libcount">
          <b>本馆已有：</b>
          <span>{{ item.libcount }}</span>
        </template>
      </div>
    </div>
    <div class="units" v-if="haveUnits">
      <p @click="showUnits">
        {{ expand ? '隐藏学科包明细 &lt;&lt;' : '查看学科包明细 >>' }}
      </p>
      <div class="unit-list" v-if="expand">
        <unit-list :units="item.relatedlist" @passId="passId" @star="doStar" />
      </div>
    </div>
  </div>
</template>

<script>
import UnitList from './UnitList'

export default {
  name: '',
  components: {
    UnitList,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      expand: false,
      loading: false,
    }
  },
  watch: {
    item(val) {
      if (!val.relatedlist) this.expand = false
    },
  },
  computed: {
    haveUnits() {
      if (!this.item.unitcount) return false
      else return true

      const units = this.item.relatedlist
      if (!units || !units.length) return false
      return true
    },
  },
  methods: {
    buttonClick(button) {
      if (button.text == '浏览书目') {
        this.$emit('passId', {
          id: this.item.ruid,
          type: 'packageruid',
          name: this.item.name,
        })
      } else if (button.text.includes('评论')) {
        this.$emit('star', button.url)
      }
    },
    passId(obj) {
      this.$emit('passId', obj)
    },
    showUnits() {
      if (this.expand) return (this.expand = false)

      this.loading = true
      const item = this.item
      this.getUnits()
        .then((units) => {
          item.relatedlist = units
          this.item = item
          this.loading = false
          this.expand = true
        })
        .catch(() => (this.loading = false))
    },
    async doStar(url) {
      this.loading = true
      const res = await this.$http.get(url)
      if (res.cxajaxrc != 0) return false

      let msg = ''
      if (url.includes('cancel')) {
        msg = '取消评论成功'
      } else msg = '评论成功'

      this.getUnits().then((units) => {
        this.item.relatedlist = units
        this.$message.success(msg)
        this.loading = false
      })
    },
    async getUnits() {
      const id = this.item.ruid
      const res = await this.$http.post(
        '/api/v1/datasearch/ebook_collection_unit_list?packageruid=' + id
      )
      return res.returnvalue.recordlist
    },
  },
  mounted() {},
}
</script>

<style lang="scss" scoped>
.package {
  .top {
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 1.2rem;
    }
  }
  .info {
    .row {
      display: block;
      line-height: 1.5rem;
      span {
        margin-right: 20px;
      }
    }
  }
  .units {
    margin-top: 10px;
    > p {
      color: $mainColor;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .unit-list {
      margin-top: 10px;
      background-color: #eaf3fb;
    }
  }
}
</style>
