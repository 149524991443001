let listRows = [
  {
    title: '出版社',
    content: 'publisher',
    inline: true,
    link: true,
  }, {
    title: '出版时间范围',
    content: 'publisherYearStart',
    inline: true,
  }, {
    title: '平台网址',
    content: 'homeurl',
    inline: true,
    link: true,
  }, {
    title: '资料类型',
    content: 'datamode',
    inline: true,
  }, {
    title: '内容类型',
    content: 'contenttype',
    inline: true,
  }, {
    title: '学科分类',
    content: 'subject',
  }, {
    title: '是否支持单册销售',
    content: 'salesingle',
    inline: true,
  }, {
    title: '读者群',
    content: 'reader',
    inline: true,
  }, {
    title: '语种',
    content: 'language',
  }, {
    title: '资源量',
    content: 'bookcount',
    inline: true,
  }, {
    title: '学科包',
    content: 'packagecount',
    inline: true,
  }, {
    title: '订购单元',
    content: 'unitcount',
    inline: true,
  }
]

let detailRows = [
  {
    title: '出版社',
    content: 'publisher',
    inline: true,
    link: true,
  }, {
    title: '出版时间范围',
    content: 'publisherYearStart',
    inline: true,
  }, {
    title: '平台网址',
    content: 'homeurl',
    link: true,
  }, {
    title: '资料类型',
    content: 'datamode',
    inline: true,
  }, {
    title: '内容类型',
    content: 'contenttype',
    inline: true,
  }, {
    title: '学科分类',
    content: 'subject',
  }, {
    title: '是否支持单册销售',
    content: 'salesingle',
    inline: true,
  }, {
    title: '读者群',
    content: 'reader',
    inline: true,
  }, {
    title: '语种',
    content: 'language',
  }, {
    title: '资源量',
    content: 'bookcount',
    inline: true,
  }, {
    title: '学科包',
    content: 'packagecount',
    inline: true,
  }, {
    title: '订购单元',
    content: 'unitcount',
    inline: true,
  }, {
    title: '简介',
    content: 'description',
  }
]

let dataListRows = [
  {
    title: '作者信息',
    content: 'authorinfo',
  }, {
    title: '出版社',
    content: 'publisher',
    inline: true,
    link: true,
  }, {
    title: '出版时间',
    content: 'publishyear',
  }, {
    title: '价格',
    content: 'price',
  }
]
export { listRows, detailRows, dataListRows }