<template>
  <div class="table-container">
    <div class="table-top">
      <span>全部订购单元</span>
      <div class="subjects">
        <div>
          <span>学科：</span>
          <el-select v-model="subject" clearable>
            <el-option v-for="item in subjects" :key="item" :label="item" :value="item" />
          </el-select>
        </div>
        <el-button type="primary" @click="setSubject">确认</el-button>
      </div>
    </div>
    <div class="table-wrapper">
      <el-table :data="tableData" max-height="700" ref="table">
        <el-table-column label="序号" type="index" align="center" width="80px" />
        <el-table-column label="订购单元名称" prop="name" width="360px" sortable>
          <template slot-scope="scope">
            <span class="unit-name" @click="viewBooks(scope.$index)">{{ unitName(scope.$index) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="学科" prop="subject" width="240px" sortable />
        <el-table-column label="收录时间范围" prop="publishyear" sortable />
        <el-table-column label="资源数量" prop="bookcount" class-name="book-count" sortable />
        <el-table-column label="价格" prop="priceLabel" sortable />
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    units: Array,
    subjects: Array,
  },
  data() {
    return {
      subject: '',
      filterSubject: '',
    }
  },
  computed: {
    tableData() {
      if (!this.filterSubject) {
        return this.units
      } else {
        return this.units.filter(item => item.subject.includes(this.filterSubject))
      }
    }
  },
  methods: {
    viewBooks(index) {
      const unit = this.$refs.table.tableData[index]
      this.$emit('viewBooks', unit)
    },
    unitName(index) {
      const unit = this.$refs.table.tableData[index]
      return unit.name
    },
    setSubject() {
      this.filterSubject = this.subject
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
  .table-container {
    border: 1px solid #E6E6E6;
    margin-top: 20px;
    .table-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #eee;
      color: #333;
      padding: 5px 30px;
      span {
        font-size: 18px;
      }
      .subjects {
        display: flex;
        div {
          display: flex;
          align-items: center;
          span {
            word-break: keep-all;
            font-size: 16px;
          }
          .el-select {
            width: 200px;
            /deep/ .el-input__inner {
              height: 34px;
              border-radius: 0;
            }
            /deep/ .el-input__suffix {
              top: -2px;
              .el-input__suffix-inner {
                display: inline-block;
              }
            }
          }
        }
        button {
          margin-left: 10px;
          padding: 6px 10px;
          height: 34px;
        }
      }
    }
    .table-wrapper {
      padding: 30px;
      .unit-name {
        cursor: pointer;
        &:hover {
          color: $mainColor;
        }
      }
      /deep/ .el-table {
        .el-table__header {
          .el-table__cell {
            padding: 3px;
            color: #666;
          }
        }
        .el-table__body {
          border-collapse: collapse;
          tbody {
            border: 1px solid #e6e6e6;
            border-top: 0;
            .el-table__cell {
              padding: 3px;
              font-size: 16px;
              color: #666;
            }
            .book-count {
              color: #FF5555 !important;
            }
          }
        }
      }
    }
  }
</style>
