<template>
  <div class="book-detail">
    <div class="left">
      <div class="cover" @click="toDetail">
        <img :src="getImage">
      </div>
    </div>
    <div class="info">
      <div class="titleen" v-html="book.title" @click="toDetail" />
      <div class="titlecn" v-html="book.titlecn" />
      <div class="detail">
        <div class="detail-row" v-if="book.authorinfo">
          <div class="detail-col" >
            <span class="detail-key">作者信息：</span>
            <span class="detail-value" v-html="book.authorinfo"></span>
          </div>
        </div>
        <div class="detail-row" v-if="book.publisher||book.publishDate||book.copyrightYear">
          <div class="detail-col" v-if="book.publisher">
            <span class="detail-key">出版社：</span>
            <span class="detail-value" v-html="book.publisher"></span>
          </div>
          <div class="detail-col" v-if="book.publishDate">
            <span class="detail-key">出版时间：</span>
            <span class="detail-value">{{book.publishDate}}</span>
          </div>
          <div class="detail-col" v-if="book.copyrightYear">
            <span class="detail-key">版权年：</span>
            <span class="detail-value">{{book.copyrightYear}}</span>
          </div>
        </div>
        <div class="detail-row" v-if="book.isbn||book.isbn10">
          <div class="detail-col" v-if="book.isbn">
            <span class="detail-key">ISBN13：</span>
            <span class="detail-value " v-html="book.isbn"></span>
          </div>
          <div class="detail-col" v-if="book.isbn10">
            <span class="detail-key">ISBN10：</span>
            <span class="detail-value " v-html="book.isbn10"></span>
          </div>
        </div>
        <div class="detail-row" v-if="false&&book.seriestitle">
          <div class="detail-col" v-if="book.seriestitle">
            <span class="detail-key">丛书：</span>
            <span class="detail-value">{{book.seriestitle}}</span>
          </div>
        </div>
        <div class="detail-row" v-if="book.language||book.contentType">
          <div class="detail-col" v-if="false&&book.binding">
            <span class="detail-key">装帧：</span>
            <span class="detail-value">{{book.binding}}</span>
          </div>
          <div class="detail-col" v-if="false&&book.page">
            <span class="detail-key">页码：</span>
            <span class="detail-value">{{book.page}}</span>
          </div>
          <div class="detail-col" v-if="book.language">
            <span class="detail-key">语种：</span>
            <span class="detail-value">{{book.language}}</span>
          </div>
          <div class="detail-col" v-if="book.contentType">
            <span class="detail-key">内容类型：</span>
            <span class="detail-value">{{book.contentType}}</span>
          </div>
        </div>
        <div class="detail-row" v-if="book.pricetype||book.priceUpdateTime">
          <div class="detail-col" v-if="book.pricetype">
            <span class="detail-key detail-pricetype">{{book.pricetype}}{{book.price}}</span>
            <span class="detail-value " v-if="book.cnyprice">{{book.cnyprice}}</span>
          </div>
          <div class="detail-col" v-if="book.priceUpdateTime">
            <span class="detail-key">价格更新时间：</span>
            <span class="detail-value">{{book.priceUpdateTime}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import urlSet from '@/requests/url'

export default {
  name: 'DataBookDetail',
  props: {
    book: Object,
    rows: Array,
  },
  data() {
    return {
      noImg: require('images/no-image.png'),
    }
  },
  computed: {
    getImage() {
      const id = this.book.coverruid
      if (!id) return this.noImg
      return id.substring(0, 4) === 'http' ? id : `${urlSet.fileUrl}/api/v1/fileredirect/file?ruid=${id}`
    },
    bookType() {
      const type = this.book.dmcode
      if (type == '1987051001') {
        return '纸书'
      } else if (type == '1987052001') {
        return '电子书'
      } else if (type == '1987054001') {
        return '纸+电'
      } else if (type == '1987053001') {
        return '数据库电子书'
      } else {
        return '未知'
      }
    }
  },
  methods: {
    toDetail() {
      let query = { id: this.book.ruid }
      if (this.bookType == '纸+电') query.type = 1
      let url = this.$router.resolve({
        name: 'BookDetail',
        query
      });
      window.open(url.href, '_blank')
    },
    buttonClick(btn) {
      let button = btn
      button.index = this.book.index
      this.$emit('button-click', button)
    },
    showInfo(content) {
      if (!content && content != 0) {
        return false
      } else {
        return true
      }
    },
    toPublisher() {
      const code = this.book.publishercustomercode
      let url = this.$router.resolve({
        name: 'PublisherDetail',
        query: { customer: code }
      });
      window.open(url.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .book-detail {
    display: flex;
    .left {
      width: 200px;
      min-width: 200px;
      .cover {
        position: relative;
        height: 200px;
        width: 200px;
        border: 1px solid #e1e1e1;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        overflow: hidden;
        img {
          display: inline-block;
          max-width: 198px;
          max-height: 198px;
          width: auto;
          height: auto;
        }
        .type {
          position: absolute;
          left: 0;
          bottom: 3px;
          padding: 5px 10px;
          color: #fff;
          background-color: $mainColor;
        }
      }
      .count {
        margin-top: 15px;
        text-align: center;
        p {
          .name {
            color: #999;
          }
          .value {
            color: #ee4e0f;
          }
        }
      }
    }
    .info {
      word-break: break-all;
      margin-left: 20px;
      max-width: 500px;
      .titleen{
        font-size: 18px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #333333;
        // max-width:500px;
        // overflow: hidden;
        // word-break: break-all;
        // white-space: nowrap;
        // text-overflow: ellipsis;
        cursor: pointer;
      }
      .titleen:hover{
        color:#2F8BD6;
      }
      .titlecn{
        font-size: 18px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        text-align: left;
        color: #333333;
        // max-width:500px;
        // overflow: hidden;
        // word-break: break-all;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }
      > * {
        margin-bottom: 10px;
      }
      h4 {
        font-size: 1.2rem;
        cursor: pointer;
        &:hover {
          color: $mainColor;
        }
      }
      p {
        color: #666;
        font-size: 1.2rem;
      }
      .detail {
        .detail-row{
          display: inline-block;
          width: 750px;
          .detail-col{
            float: left;
            margin-right: 20px;
            .detail-key{
              font-size: 16px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
            }
            .detail-pricetype{
              font-size: 20px;
              color: #FF5555;
            }
            .detail-value{
              font-size: 16px;
              font-family: Microsoft YaHei, Microsoft YaHei-Regular;
              font-weight: 400;
              text-align: left;
              color: #666666;
            }
            .detail-value-url{
              cursor: pointer;
              color: #2F8BD6;
              text-decoration: underline;
            }
            .detail-value-tag{
              color: #FF5555;
            }
          }
        }
        div {
          line-height: 1.5rem;
          b {
            &::after {
              content: '：';
            }
          }
          .link {
            color: $mainColor;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .inline-row {
          display: inline-block;
          margin-right: 15px;
        }
      }
      .version {
        display: block;
      }
      .buttons {
        margin-top: 20px;
      }
    }
  }
</style>
