<template>
  <el-table class="table" :data="units">
    <el-table-column
      label="名称"
      prop="name"
      width="350"
    />
    <el-table-column
      label="资源数量"
      prop="bookcount"
    />
    <el-table-column
      label="预估总价"
      prop="bookcnypricecount"
    />
    <el-table-column
      label="馆藏状态"
      prop="libinfo"
    />
    <el-table-column
      label="操作"
    >
      <template slot-scope="scope">
        <el-button
          type="text"
          v-for="btn in units[scope.$index].operation"
          :key="btn.ruid"
          @click="buttonClick(btn, units[scope.$index])"
        >{{ btn.text }}</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: '',
  components: {
  },
  props: {
    units: Array,
  },
  data() {
    return {
    }
  },
  methods: {
    buttonClick(button, unit) {
      if (button.text == '浏览书目') {
        this.$emit('passId', { id: unit.ruid, type: 'unitruid', name: unit.name })
      } else {
        this.$emit('star', button.url)
      }
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
  .table {
    background-color: transparent;
    /deep/ .el-table__header {
      width: 100% !important;
    }
    /deep/ .el-table__body {
      width: 100% !important;
    }
    /deep/ tr {
      background-color: transparent;
      th {
        background-color: transparent;
      }
      .cell {
        color: #000;
      }
    }
  }
</style>
