export default [
  {
    title: '作者信息',
    content: 'authorinfo',
  }, {
    title: '出版社',
    content: 'publisher',
    inline: true,
    link: true,
    type: 0
  },  {
    title: '出版时间',
    content: 'publishyear',
    inline: true,
  },{
    title: '丛书名',
    content: 'seriestitle',
    link: true,
    inline: true,
    type: 1
  }, {
    title: '丛书号',
    content: 'seriesno',
    inline: true,
    
  }, {
    title: 'ISBN',
    content: 'isbn',
  }, {
    title: '版次',
    content: 'edition',
    inline: true,
  }, {
    title: '页码',
    content: 'page',
    inline: true,
  }, {
    title: '语种',
    content: 'language',
    inline: true,
  }
]